<template>
  <v-app>
    <v-container
      v-if="institution"
      data-cy="login-user-institute-background-image"
      class="fill-height bg-login"
      :style="{
        'background-image': 'url(' + institution.image || '' + ') !important',
        'background-size': 'cover',
      }"
      fill-height
      fluid
    >
      <v-row align="center" justify="end" class="mr-4 ml-4">
        <v-col cols="12" sm="12" md="4">
          <v-card class>
            <div class="pt-4 pb-4">
              <v-avatar width="120" height="120" class="mb-2">
                <img
                  data-cy="login-user-institute-logo"
                  :src="institution.logo || ''"
                />
              </v-avatar>
              <h3 data-cy="login-user-institute-name">
                {{ institution.name }}
              </h3>
            </div>

            <v-divider class="mx-4"></v-divider>

            <v-form @submit="login">
              <v-card-text>
                <div class="pl-4 pr-4">
                  <p
                    v-if="formErrors.genError"
                    class="form-error"
                    data-cy="login-user-form-errors"
                  >
                    {{ formErrors.genError }}
                  </p>
                  <h4 class="text-left mb-2">Username</h4>
                  <div data-cy="login-user-username-field">
                    <v-text-field
                      v-model="username"
                      :error="formErrors.usernameError != null"
                      :error-messages="formErrors.usernameError"
                      type="text"
                      outlined
                      dense
                      rounded
                      hide-details="auto"
                      class="mb-2"
                    ></v-text-field>
                  </div>

                  <h4 class="text-left mb-2">Password</h4>
                  <div data-cy="login-user-password-field">
                    <v-text-field
                      v-model="password"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :error="formErrors.passwordError != null"
                      :error-messages="formErrors.passwordError"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      outlined
                      dense
                      hide-details="auto"
                      rounded
                    ></v-text-field>
                  </div>
                </div>
                <div class="text-center">
                  <v-progress-circular
                    v-if="isLoading"
                    indeterminate
                    size="30"
                  ></v-progress-circular>
                  <div class="mt-4 mr-3 ml-3 pb-4" v-else>
                    <v-btn
                      color="primary"
                      data-cy="login-user-submit-button"
                      type="submit"
                      block
                      >Login</v-btn
                    >
                  </div>
                </div>
                <div>
                  <a
                    @click="getForgotPassword()"
                    class="text-decoration-underline"
                  >
                    Forgot Password?
                  </a>
                </div>
              </v-card-text>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
      <v-overlay :value="isLoading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <ConfirmationDialog
        v-if="resetPassword.userEmail"
        :title="`An Email with the Reset Password link has been sent to ${Helper.obscureEmail(
          resetPassword.userEmail
        )}`"
        description="Please go to your Email and click on the sent link to reset your password"
        successText="OK"
        :visible="resetPassword.showEmailSentDialog"
        @successCallback="
          resetPassword.showEmailSentDialog = false;
          userEmail = null;
        "
      />
      <ConfirmationDialog
        title="Forgot Password?"
        description="Please enter your login username to proceed"
        successText="Proceed"
        @successCallback="checkUsernameExists()"
        @failureCallback="closeDialog"
        failureText="Cancel"
        :visible="resetPassword.showDialog"
      >
        <slot>
          <v-form
            @submit.prevent="checkUsernameExists()"
            v-model="resetPassword.resetPasswordForm"
            ref="form"
          >
            <v-text-field
              v-model="resetPassword.passwordResetUsername"
              class="rounded-input mx-5 my-2"
              outlined
              :error-messages="formErrors.resetPasswordUsernameError"
              @input="formErrors.resetPasswordUsernameError = null"
              hide-details="auto"
              dense
              clearable
              placeholder="Username"
            />
          </v-form>
        </slot>
      </ConfirmationDialog>

      <v-snackbar v-model="snackbar.visible">
        {{ snackbar.text }}
        <template>
          <v-btn color="pink" text @click="snackbar.visible = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </v-app>
</template>


<script>
import { mapActions, mapGetters } from "vuex";
import api from "@api/index";
import endpoints from "@api/repository";
import Helper from "@utils/misc";
import authApi from "@api/auth/base";
import ConfirmationDialog from "@components/widgets/dialogs/ConfirmationDialog";

// import ApiError from '@utils/helper_classes';
export default {
  name: "LoginUser",
  components: { ConfirmationDialog },
  data() {
    return {
      Helper: Helper,
      bgImage: "../../assets/dy.png",
      username: "",
      password: "",
      resetPassword: {
        showDialog: false,
        resetPasswordForm: true,
        passwordResetUsername: null,
        showEmailSentDialog: false,
        userEmail: null,
      },

      showPassword: false,

      formErrors: {
        genError: null,
        usernameError: null,
        passwordError: null,
        resetPasswordUsernameError: null,
      },
      snackbar: {
        visible: false,
        text: "",
      },
      institution: null,
      fee_sessions: [],
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "currentUser",
      "currentInstitution",
      "accessToken",
    ]),
    apiEssentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },
  },
  methods: {
    ...mapActions([
      "setLoading",
      "setTokens",
      "setCurrentUser",
      "setCurrentInstitution",
      "setFeeSessionData",
      "clearTokens",
    ]),
    
    // get institution data [images]
    async getInstitutionDetails() {
      var response;
      response = await api.handleApi({
        apiEssentials: this.apiEssentials,
        url:
          endpoints.getInstitutionDetailsByCode + this.$route.params.code + "/",
        responseVar: response,
      });
      if (response) {
        this.institution = response;
      }
    },

    //show dialog for username
    getForgotPassword() {
      this.resetPassword.showDialog = true;
    },

    closeDialog() {
      this.resetPassword.showDialog = false;
      // this.currentPassword = null
      // this.newPassword = null
      // this.checkPassword = null
      this.$refs.form.reset();
    },

    async checkUsernameExists() {
      var response = await api.call(
        this.apiEssentials,
        Helper.addUrlParams(endpoints.checkUsernameExists, [
          `username=${this.resetPassword.passwordResetUsername}`,
          `code=${this.institution.code}`,
        ])
      );
      console.log(response);
      if (!response) {
        this.formErrors.resetPasswordUsernameError =
          "This username does not exist";
      } else {
        // if username exists
        // send email to user
        this.sendPasswordResetEmail();
      }
    },

    async sendPasswordResetEmail() {
      var response = await api.call(
        this.apiEssentials,
        endpoints.sendPasswordResetEmail,
        api.Methods.POST,
        {
          username: this.resetPassword.passwordResetUsername,
          code: this.institution.code,
        }
      );
      // if email not available, gives error: contact administrator
      // else emailSent
      if (response) {
        this.$refs.form.reset();
        this.resetPassword.userEmail = response;
        this.resetPassword.showDialog = false;
        this.resetPassword.showEmailSentDialog = true;
      }
    },

    // checks for any invalid or empty field
    validateFields() {
      Object.keys(this.formErrors).forEach((v) => (this.formErrors[v] = null));
      if (!this.username) {
        this.formErrors.usernameError = "This field is required";
        return false;
      }

      if (!this.password) {
        this.formErrors.passwordError = "This field is required";
        return false;
      }
      return true;
    },

    async login(e) {
      e.preventDefault();

      if (!this.validateFields()) 
        return;
      
      this.setLoading(true);
      
      //creates token from phone and password
      let tokens = await authApi.createToken({
        username: this.username,
        password: this.password,
      });

      // handle token errors
      if (!Helper.handleErrors(tokens, this.handleApiError)) return;

      // set tokens in state
      await this.setTokens(tokens);

      // get currentUser
      let currentUser = await authApi.getMyDetails(tokens.access);

      // handle currentUser errors
      if (currentUser instanceof Error) {
        this.setLoading(false);
        this.snackbar.text = "There was a server error. Please, try again!";
        this.snackbar.visible = true;
        return;
      }

      if (currentUser.institution.id != this.institution.id) {
        this.setLoading(false);
        this.formErrors.genError =
          "The user account does not belong to this institution.";
        this.clearTokens();
        return;
      }

      if (!currentUser.is_student) {
        this.setLoading(false);
        this.formErrors.genError =
          "The account does not belong to a student.";
        this.clearTokens();
        return;
      }

      //set user in state
      await this.setCurrentUser(currentUser);

      //set institution in state
      await this.setCurrentInstitution(this.institution);
      this.setLoading(false);

      // get active fee sessions
      await this.updateFeeSessionData();

      if (this.currentUser !== null && this.$route.query.next != null) {
        this.$router.push(this.$route.query.next);
      } else {
        if(this.currentInstitution && this.currentInstitution.is_super_institution) {
          this.$router.push({name: "FranchiseDashboard"});
        }
        else if(this.currentInstitution && this.currentInstitution.stage == 1) {
          this.$router.push({name: "ModulesSetup"});
        }
        else if(this.currentInstitution && this.currentInstitution.stage == 2) {
          this.$router.push({ name: 'ClassroomSetup' })
        }
        else if(this.currentInstitution && this.currentInstitution.stage == 3) {
          this.$router.push({ name: 'StudentsSetup' })
        }
        else if(this.currentInstitution && this.currentInstitution.stage == 4) {
          this.$router.push({ name: 'StaffSetup' })
        }
        else {
          this.$router.push({ name: 'FeeDashboard' })
        }
      }
    },

    async updateFeeSessionData() {
      console.log("updateFeeSessionData executed");
      var url = endpoints.getFeeSessions;

      var fee_sessions = await api.call(this.apiEssentials, url);
      this.setFeeSessionData(fee_sessions);
    },

    handleApiError(err) {
      console.log(err);
      console.log("handling errors");
      this.setLoading(false);
      if (err.response) {
        if (err.response.status == 401) {
          this.formErrors.genError = "Incorrect username or password";
        }
        if (err.response.status == 412) {
          this.formErrors.resetPasswordUsernameError =
            "Account does not have Email linked. Please contact administrator.";
        }
      } else {
        this.snackbar.text = "Unable to process request";
        this.snackbar.visible = true;
      }
    },
  },

  created() {
    // if user on login page and currentUser != null, navigate to home '/'
    if (this.currentUser != null) this.$router.push("/");
    this.getInstitutionDetails();
  },
};
</script>

<style scoped>
</style>